<template>
  <div>
    
   <h1>メールマガジン購読の登録・解除</h1>
   
   
   <div class="contactform" v-if="view!='complete'">
     <div v-if="view=='confirm'">

       <strong>まだ送信は完了していません。</strong><br>以下の内容で送信してよろしいですか？
       <div class="bns">
           <button @click="prev">前に戻る</button>
           <button @click="submit">この内容を送信</button>
           </div>

           
          <table>
            <tbody>
              <tr>
                <th colspan="2" class="formListTh3">メールマガジン購読の登録・解除フォーム</th>
              </tr><!--
              <tr v-if="mode=='subscribe'">
                <th align="right" class="formListTh">お名前：</th>
                <td align="left" class="formListTd">{{name?name:"-"}}</td>
              </tr>-->
              <tr>
                <th align="right" class="formListTh">メールアドレス：</th>
                <td align="left" class="formListTd">{{email}}</td>
              </tr>
            </tbody>
          </table> 

          
       <div class="bns">
           <button @click="prev">前に戻る</button>
           <button @click="submit">この内容を送信</button>
           </div>

     </div>
     <div v-else>
       
       <div class="modeselector">
            <input type="radio" name="modechk" class="zoomcheck" value="subscribe" v-model="mode"> 登録する　<input type="radio" name="modechk" class="zoomcheck" value="unsubscribe" v-model="mode"> 解除する
</div>
            <div v-if="mode=='subscribe'">
          <table>
            <tbody>
              <tr>
                <th colspan="2" class="formListTh3">メールマガジン購読の登録フォーム</th>
              </tr><!--
              <tr>
                <th align="right" class="formListTh">お名前：</th>
                <td align="left" class="formListTd"><input v-model="name" name="name" type="text" class="fm1" value="" size="20">
                </td>
              </tr>-->
              <tr>
                <th align="right" class="formListTh">登録するメールアドレス：</th>
                <td align="left" class="formListTd"><input v-model="email" name="email" type="text" class="fm1" value="" size="20">
                *必須</td>
              </tr>
			<tr>
                <th align="right" class="formListTh">登録するメールアドレス（確認）：</th>
                <td align="left" class="formListTd"><input v-model="email_c" name="email2" type="text" class="fm1" value="" size="20">
                *必須</td>
            </tr>
            </tbody>
          </table> 

           <button @click="confirm">メールマガジンの登録</button>
</div>
          
          
            <div v-if="mode=='unsubscribe'">
          <table>
            <tbody>
              <tr>
                <th colspan="2" class="formListTh3">メールマガジン購読の解除フォーム</th>
              </tr>
              <tr>
                <th align="right" class="formListTh">解除するメールアドレス：</th>
                <td align="left" class="formListTd"><input v-model="email" name="email" type="text" class="fm1" value="" size="20">
                *必須</td>
              </tr>
			<tr>
                <th align="right" class="formListTh">解除するメールアドレス（確認）：</th>
                <td align="left" class="formListTd"><input v-model="email_c" name="email2" type="text" class="fm1" value="" size="20">
                *必須</td>
            </tr>
            </tbody>
          </table> 


           <button @click="confirm">メールマガジンの解除</button></div>
          </div>
       
</div>
<div class="contactform_end" v-else>
            <div v-if="mode=='subscribe'">
  ありがとうございます。メールマガジンへの登録を完了しました。
  </div>
  <div v-if="mode=='unsubscribe'">
  ありがとうございました。メールマガジンへの登録を解除しました。
    </div>
</div>



    <dialogalert
      v-model="visible_dialog"
      :title="dialog_title"
      :info="dialog_info"
      :button_ok="dialog_bn_ok"
      :button_cancel="dialog_bn_cancel"
      :ok_fn="dialog_ok_fn"
    />


  </div>
</template>

<script>
export default  {
  beforeRouteLeave(to, from, next) {
    if(this.name || this.subject || this.number | this.email ){
      if(window.confirm("編集中のものは保存されませんが、よろしいですか？")){
          next();
        }else{
          return;
        }
    }else{
      next();
    }
  },
  data:function(){
    return {
      name:"",subject:"",number:"",email:"",email_c:"",mode:"subscribe",
      view:"edit"
    }
  },
    created: function() { 
      this.$parent.middle = 2; 
    window.addEventListener("beforeunload", this.confirmSave);
      },
  destroyed () {
    window.removeEventListener("beforeunload", this.confirmSave);
  },
      methods:{
    confirmSave (event) {
      if(this.name || this.subject || this.number | this.email)
      event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
    },
        prev:function(){      
              this.view="edit";   
        },
        confirm:function(){
 
          this.fetch("mailmag",{name:this.name,email:this.email,email_c:this.email_c,mode:this.mode,flow:"check"}).then((data)=>{
            if(data.error){
               this.opendialog({title:"送信エラーです。",info:data.error, bn_ok:"OK"});

            }else{  
              this.view="confirm";
              //alert("ありがとうございます。送信を完了しました。")
              //this.view="complete";
            }
          }) 
        },
        submit:function(){
          this.view="complete";
          this.fetch("mailmag",{name:this.name,email:this.email,email_c:this.email_c,mode:this.mode,flow:"submit"}).then((data)=>{
            if(data.error){

               this.opendialog({title:"送信エラーです。",info:data.error,
            bn_ok:"OK"});

            }else{

            //alert("ありがとうございます。送信を完了しました。")
              this.view="complete";
            }
          })
        }
      }
      }
</script>

<style scoped lang="scss">
$sp: 680px; // スマホ
@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}
h1{
  margin-top: 1em;
}
.contactform_end{
  padding: 7em 0;
  text-align: center;
}
.contactform{
  padding-bottom:2em ;
  table{
  width:800px;
  margin: 0 auto;
  .formListTh3{
    background: #D11E1E;
    color: #fff;
  }
  th{
    padding: .7em;
    background: rgb(226, 226, 226);
    color: rgb(49, 49, 49);
  }
  td{
    padding: .7em;
  }
  }
    @include sp{
table{
  display: block;
  width: 90%;
  margin: 0 auto;
  th,td{
    display: block;
    text-align: left;
  }
}

    }
}
.modeselector{
  padding: 1em 0;;
}
</style>